<template>
  <div>
    <main class="page-content">
      <!-- iterating -->
      <div
        v-for="(elem, index) in classesList"
        :key="index"
        class="card"
        :style="setBackground(elem.cover_img_url)"
      >
        <div class="tags tagPos">
          <a>{{ elem.cover_tag[showLang] }}</a>
        </div>

        <h2 class="title">
          {{ helper.displayI18nText($i18n.locale, elem.title) }}
        </h2>

        <div class="content">
          <div class="copy">
            <p>{{ helper.displayI18nText($i18n.locale, elem.summary) }}</p>
            <p>Ap ® Mocks | {{ $t("classesandcounseling.summer") }}</p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";

export default {
  data() {
    return {
      classesList: [
        {
          id: 9,
          title: {
            en: "Application Counseling",
            tw: "代辦+顧問",
            cn: "代办+顾问"
          },
          cover_img_url:
            "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/online_courses/cover/2020-09-30_5f74f621a4412_admissions.jpg",
          cover_tag: { en: "Year-round", tw: "全年度", cn: "全年度" },
          summary: {
            en:
              "Our counselors help students dazzle on their applications, gain into their dream schools, and train students important application and marketing skills.",
            tw:
              "我們的顧問教導學生如何在申請表耀眼奪目，獲得學生理想大學的錄取通知，並教導學生重要的自我推薦能力。",
            cn:
              "我们的顾问教导学生如何在申请表耀眼夺目，获得学生理想大学的录取通知，並教导学生重要的自我推荐能力。"
          }
        },
        {
          id: 8,
          title: {
            en: "Ap ® Mocks Guaranteed Mentoring Program",
            tw: "Ap ® Mocks 培訓保證計畫",
            cn: "Ap ® Mocks 培训保证计划"
          },
          cover_img_url:
            "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/online_courses/cover/2020-09-30_5f74f23209d8b_mentoring.jpg",
          cover_tag: { cn: "全年度", tw: "全年度", en: "Year-round" },
          page_url: "MentoringProgram",
          summary: {
            en:
              "Our 1-stop mentoring program covers all in-school and after-school tutoring and counseling and guarantee your admission to top universities in the US.",
            tw:
              "我們的保證計計劃是一套所有課內外補習跟顧問全包的長期輔導課程，並保證您能獲得名校的錄取。",
            cn:
              "我们的保证计划是一套所有课内外补习跟顾问全包的长期辅导课程，并保证您能获得名校的录取。"
          }
        },
        {
          id: 14,
          title: {
            en: "Ap ® Mocks Advanced",
            tw: "Ap ® Mocks 模考班",
            cn: "Ap ® Mocks 模考班"
          },
          cover_img_url:
            "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/online_courses/cover/2021-08-31_612de371c9907_1.JPG",
          cover_tag: { en: "Ap ® Mocks 模考班", tw: null, cn: null },
          page_url: null,
          summary: { en: null, tw: null, cn: null }
        },
        {
          id: 8,
          title: {
            en: "Ap ® Mocks Guaranteed Mentoring Program",
            tw: "Ap ® Mocks 培訓保證計畫",
            cn: "Ap ® Mocks 培训保证计划"
          },
          cover_img_url:
            "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/online_courses/cover/2020-09-30_5f74f23209d8b_mentoring.jpg",
          cover_tag: { cn: "全年度", tw: "全年度", en: "Year-round" },
          page_url: "MentoringProgram",
          summary: {
            en:
              "Our 1-stop mentoring program covers all in-school and after-school tutoring and counseling and guarantee your admission to top universities in the US.",
            tw:
              "我們的保證計計劃是一套所有課內外補習跟顧問全包的長期輔導課程，並保證您能獲得名校的錄取。",
            cn:
              "我们的保证计划是一套所有课内外补习跟顾问全包的长期辅导课程，并保证您能获得名校的录取。"
          }
        },
        {
          id: 12,
          title: {
            en: "Ap ® Mocks (Winter) SAT Course",
            tw: "Ap ® Mocks SAT (寒假) 課程",
            cn: "Ap ® Mocks SAT (寒假) 课程"
          },
          cover_img_url:
            "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/online_courses/cover/2020-09-30_5f74f987617de_sat_part1.jpg",
          cover_tag: { en: "Winter", tw: "冬假", cn: "冬假" },
          page_url: "SATCourseWinter",
          summary: {
            en:
              "Our flagship 2-week Ap ® Mocks SAT Course is the most effective program in Taiwan designed to help students of all levels in \"tutor-style\" small classes.",
            tw:
              "小班制 \"家教式\" 教學，能力分班適合各程度的學生，我們的 Ap ® Mocks 旗艦 2 禮拜課程是台灣最有效的 SAT 課程。",
            cn:
              "小班制 \"家教式\" 教学，能力分班适合各程度的学生，我们的 Ap ® Mocks 旗舰 2 礼拜 SAT 课程是台湾最有效的 SAT 课程。"
          }
        },
        {
          id: 8,
          title: {
            en: "Ap ® Mocks Guaranteed Mentoring Program",
            tw: "Ap ® Mocks 培訓保證計畫",
            cn: "Ap ® Mocks 培训保证计划"
          },
          cover_img_url:
            "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/online_courses/cover/2020-09-30_5f74f23209d8b_mentoring.jpg",
          cover_tag: { cn: "全年度", tw: "全年度", en: "Year-round" },
          page_url: "MentoringProgram",
          summary: {
            en:
              "Our 1-stop mentoring program covers all in-school and after-school tutoring and counseling and guarantee your admission to top universities in the US.",
            tw:
              "我們的保證計計劃是一套所有課內外補習跟顧問全包的長期輔導課程，並保證您能獲得名校的錄取。",
            cn:
              "我们的保证计划是一套所有课内外补习跟顾问全包的长期辅导课程，并保证您能获得名校的录取。"
          }
        },
        {
          id: 12,
          title: {
            en: "Ap ® Mocks (Summer) SAT Course",
            tw: "Ap ® Mocks SAT (暑假) 課程",
            cn: "Ap ® Mocks SAT (暑假) 课程"
          },
          cover_img_url:
            "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/online_courses/cover/2020-09-30_5f74f987617de_sat_part1.jpg",
          cover_tag: { en: "Summer", tw: "暑假", cn: "暑假" },
          page_url: "SATCourseWinter",
          summary: {
            en:
              "Our flagship 2-week Ap ® Mocks SAT Course is the most effective program in Taiwan designed to help students of all levels in \"tutor-style\" small classes.",
            tw:
              "小班制 \"家教式\" 教學，能力分班適合各程度的學生，我們的 Ap ® Mocks 旗艦 2 禮拜課程是台灣最有效的 SAT 課程。",
            cn:
              "小班制 \"家教式\" 教学，能力分班适合各程度的学生，我们的 Ap ® Mocks 旗舰 2 礼拜 SAT 课程是台湾最有效的 SAT 课程。"
          }
        },
        {
          id: 3,
          title: {
            tw: "Ap ® Mocks SAT 班, Part 2",
            en: "Ap ® Mocks SAT Course, Part 2",
            cn: "Ap ® Mocks SAT 班, Part 2"
          },
          cover_img_url:
            "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/online_courses/cover/2020-08-07_5f2cd4296adf9_sat_part2.jpg",
          cover_tag: { tw: "暑期", cn: "暑期", en: "Summer" },
          page_url: "SATCoursePart2",
          summary: {
            en:
              "Our Ap ® Mocks SAT/ACT Course continues for a second year to help you perfect the skills you learned last year.",
            tw:
              "延續第二年上我們的 Ap ® Mocks SAT/ACT 課程，把去年學到的技巧用得更淋漓盡致！",
            cn:
              "延续第二年上我们的 Ap ® Mocks SAT/ACT 课程，把去年学到的技巧用得更淋漓尽致！"
          }
        },
        {
          id: 5,
          title: {
            tw: "SAT Math 技巧班",
            en: "SAT Math Course",
            cn: "SAT Math 技巧班"
          },
          cover_img_url:
            "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/online_courses/cover/2020-08-07_5f2cd527a0c95_math_skill.jpg",
          cover_tag: { tw: "暑期", cn: "暑期", en: "Summer" },
          page_url: "SATMath",
          summary: {
            en:
              "SAT Summer Program teaches English and optional math (free). If you only want to take a math class, you may do so too.",
            tw:
              "SAT 暑期班含英文跟可选修的数学（免费）。如果您单独需要数学，您也可以只上数学课。",
            cn:
              "SAT 暑期班含英文跟可选修的数学（免费）。如果您单独需要数学，您也可以只上数学课。"
          }
        }
      ],
      showLang: "en"
    };
  },
  computed: {
    helper() {
      return helper;
    },
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("classesandcounseling", ["getCourses"], ["lang"])
  },
  watch: {
    getCourses(newVal) {
      this.classesList = newVal.courses.filter(({ show_index }) => show_index);
    },
    lang(val) {
      if (val.value == "ZH-CN") {
        this.showLang = "cn";
      } else if (val.value == "ZH-TW") {
        this.showLang = "tw";
      } else if (val.value == "EN-US") {
        this.showLang = "en";
      }
    }
  },
  async mounted() {
    const payload = { is_visible: 1, show_index: 1 };
    await this.$store.dispatch("classesandcounseling/getCourses", payload);
  },
  methods: {
    toRouteWithParams(classData) {
      if (classData.page_url) {
        this.$router.push({
          name: classData.page_url,
          params: {
            classData
          }
        });
      } else {
        this.$router.push({
          name: "ClassTemplate",
          params: {
            courseId: classData.id
          }
        });
      }
    },
    setBackground(bgUrl) {
      return {
        backgroundImage: "url(" + bgUrl + ")"
      };
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/tags.scss";
</style>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Cardo:400i|Rubik:400,700&display=swap");
@import "@/assets/scss/tags.scss";

$bp-md: 750px;
$bp-lg: 1100px;

:root {
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
  --font-sans: "Rubik", sans-serif;
  --font-serif: "Cardo", serif;
}

.page-content {
  display: grid;
  grid-gap: 3rem;
  padding: 1rem;
  margin: 3rem 3rem;
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: var(--font-sans);

  @media (max-width: 450px) {
    margin: 2rem 1rem;
    grid-gap: 3rem;
  }

  @media (min-width: $bp-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $bp-lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 0;
  width: 100%;
  height: 270px;
  text-align: center;
  cursor: pointer;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1);

  // @media (max-width: $bp-md) {
  //   height: 300px;
  // }

  &:after {
    content: "";
    position: relative;
    top: 135px;
    left: 0;
    width: 100%;
    height: 200px;
    pointer-events: none;
    transform: translateY(-50%);
    transition: transform calc(var(--d) * 2) var(--e);
  }
}

// 調整 hover 後顯示內容區塊
.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  height: 0px;
  width: 100%;
  padding: 0 2rem;
  margin: 0px;
  transition: transform 1s cubic-bezier(0.3, 1, 0.4, 1);
  z-index: 1;
  transform: translateY(220px);

  > * + * {
    margin-top: 1rem;
  }
  > *:not(.title) {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform var(--d) var(--e), opacity var(--d) var(--e);
  }
}

// 調整標題
.title {
  position: absolute;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
  color: rgba(33, 33, 33, 1);
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  margin: 0px;
  width: 100%;
}

// 調整右下角 tag
.tagPos {
  z-index: 1000;
  position: relative;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.2;
  margin: 210px -15px 30px 0px;

  a {
    color: #fff !important;
  }
}

// 課程介紹區塊
.copy {
  position: relative;
  top: 0px;
  font-family: var(--font-serif);
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
  height: 0px;
  padding: 0;
  margin: 0;
}

// 小於 450 則 tag 位置往下調整
@media (max-width: 450px) {
  .content .copy {
    font-size: 0.9rem;
  }
}

.card:after {
  transform: translateY(0);
}

// @media (min-width: 970px) {
//   .copy {
//     padding: 2px 25px 0px 25px;
//   }
// }

// @media (min-width: 751px) {
//   .copy {
//     padding: 2px 25px 0px 25px;
//   }
// }

// 微調 response 顯示
// @media (max-width: $bp-md) {
//   .copy {
//     padding: 5px 15px 0px 15px;
//     height: 80px;
//   }
// }

// @media (max-width: 500px) {
//   .copy {
//     padding: 3px 3px 0px 3px;
//     height: 90px;
//   }
// }

.card:hover,
.card:focus-within {
  align-items: center;
  height: 270px;

  &:before {
    transform: translateY(-4%);
    height: 270px;
  }
  &:after {
    transform: translateY(-50%);
    height: 270px;
    // hover 後增加漸層黑色透明背景
    background-image: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.5) 22.1%,
      hsla(0, 0%, 0%, 0.5) 39.4%,
      hsla(0, 0%, 0%, 0.5) 53.1%,
      hsla(0, 0%, 0%, 0.5) 64.3%,
      hsla(0, 0%, 0%, 0.6) 74.1%,
      hsla(0, 0%, 0%, 0.7) 83.6%,
      hsla(0, 0%, 0%, 0.8) 94.1%,
      hsla(0, 0%, 0%, 0.9) 100%
    );
  }

  .content {
    transform: translateY(100px);
    height: 170px;

    > *:not(.title) {
      opacity: 1;
      transform: translateY(0);
      transition-delay: calc(var(--d) / 8);
    }
  }
}

.card:focus-within {
  &:before,
  &:after,
  .content,
  .content > *:not(.title) {
    transition-duration: 0s;
  }
}

// hover 後不顯示 tag
.card:hover .tagPos {
  display: none;
}
</style>
